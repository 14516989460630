<template>
  <div
    v-if="orderInfo && orderInfo.shippingOrderNo"
    class="delivery-info"
  >
    <div class="message">{{ $t('account.orderDetail.shipmentMessage') }}</div>
    <div class="info">
      <span>{{ orderInfo.shippersName }}&nbsp;{{ $t('account.orderDetail.TrackingNumber') }}:&nbsp;</span>
      <span>{{ orderInfo.shippingOrderNo }}</span>
    </div>
    <div
      class="number"
      @click="showDeliveryDetailFn"
    >{{ $t('account.orderDetail.TrackDetailText') }}</div>
    <order-delivery-detail
      v-if="showDeliveryDetail"
      :shipping-info="shippingInfo"
      :is-show="showDeliveryDetail"
      @isLoading="isLoading"
      @close="close"
    />
  </div>
</template>
<script>
import OrderDeliveryDetail from './deliver-detail.vue'

export default {
  components: {
    OrderDeliveryDetail,
  },
  props: {
    orderInfo: {
      type: Object,
    },
    shippingInfo: {
      type: Object,
    },
    fromEmail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      test: '123',
      showDeliveryDetail: false,
    }
  },
  created() {
    if (this.fromEmail) {
      this.showDeliveryDetailFn()
    }
  },
  methods: {
    showDeliveryDetailFn() {
      this.showDeliveryDetail = true
    },
    close() {
      this.showDeliveryDetail = false
    },
    isLoading(data) {
      this.$emit('isLoading', data)
    },
  },
}
</script>
<style scoped lang="less">
.delivery-info {
  padding: 0 24px 24px;
  line-height: 30px;
  .info {
  }

  .number {
    color: #00b876;
    cursor: pointer;
  }
}
</style>
