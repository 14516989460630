<template>
  <div class="bill-detail">
    <div class="bill-detail__title">{{ `No.${billDetail.orderNo}` }}</div>
    <div class="bill-detail__base">
      <div class="bill-detail__base-item">
        <div class="bill-detail__info-title">{{ $t('account.orderList.orderStatus') }}</div>
        <div class="bill-detail__info-content">{{ orderStatusName }}</div>
      </div>
      <div class="bill-detail__base-item">
        <div class="bill-detail__info-title">{{ $t('account.orderDetail.orderTime') }}</div>
        <div class="bill-detail__info-content">{{ billDetail.orderTime | formatDateTime }}</div>
      </div>
      <div
        v-if="showPayName"
        class="bill-detail__base-item"
      >
        <div class="bill-detail__info-title">{{ $t('account.orderDetail.paymentMethod') }}</div>
        <div class="bill-detail__info-content">{{ billDetail.payChannelName }}</div>
      </div>
      <div
        v-if="isPaySuccess"
        class="bill-detail__base-item"
      >
        <div class="bill-detail__info-title">{{ $t('account.orderDetail.paymentTime') }}</div>
        <div class="bill-detail__info-content">{{ billDetail.payTime | formatDateTime }}</div>
      </div>
    </div>
    <div class="bill-detail__title">{{ $t('account.orderDetail.contactInfo') }}</div>
    <div class="bill-detail__contact">
      <div class="bill-detail__contact-item">
        <div class="bill-detail__info-title">{{ $t('account.orderDetail.contactName') }}</div>
        <div class="bill-detail__info-content">{{ billDetail.customerName }}</div>
      </div>
      <div class="bill-detail__contact-item">
        <div class="bill-detail__info-title">{{ $t('account.orderDetail.contactEmail') }}</div>
        <div class="bill-detail__info-content">{{ billDetail.customerEmail }}</div>
      </div>
    </div>
    <div v-if="isPickUP">
      <div class="bill-detail__title">{{ $t('payment.shipping.pickUpAddress') }}</div>
      <div>{{ pickUpAddress }}</div>
    </div>
    <div v-else>
      <div class="bill-detail__title">{{ $t('account.orderDetail.shippingAddress') }}</div>
      <div class="bill-detail__shipping">
        <div class="bill-detail__shipping__detail">
          <div class="bill-detail__shipping__detail__item">{{ billDetailName }}</div>
          <div class="bill-detail__shipping__detail__item">{{ phone }}</div>
          <div class="bill-detail__shipping__detail__item">{{ detailAddress }}</div>
        </div>
      </div>
    </div>
    <div
      v-if="billDetail.orderStatus === ORDER_STATUS.UNPAID && billDetail.payMethodType !== 7"
      class="bill-detail__pay-btn"
    >
      <op-button @click="payOrder">{{ $t('account.orderDetail.pay') }}</op-button>
    </div>
  </div>
</template>

<script>
import { ORDER_STATUS, PAY_STATUS, SHIPPING_TYPE } from '@/constants'
const codCode = 7
export default {
  components: {},
  props: {
    billDetail: {
      type: Object,
      default: () => ({}),
    },
    shippingType: {
      type: Number,
      default: SHIPPING_TYPE.LOGISTiCS,
    },
    shippingDetail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ORDER_STATUS,
      SHIPPING_TYPE,
    }
  },

  computed: {
    orderStatusName() {
      if (this.billDetail?.payMethodType === codCode) {
        return this.billDetail.payStatusName
      }
      return this.billDetail.orderStatusName
    },
    isPickUP() {
      return this.shippingType === SHIPPING_TYPE.PICK_UP
    },
    billDetailName() {
      return (this.billDetail.firstName || '') + ' ' + (this.billDetail.lastName || '')
    },
    phone() {
      return (this.billDetail.internationalCode || '') + ' ' + (this.billDetail.phone || '')
    },
    pickUpAddress() {
      const keyList = ['address', 'city', 'stateValue']
      return keyList
        .map(key => (this.shippingDetail || {})[key] || '')
        .filter(item => !!item)
        .join(',')
    },
    detailAddress() {
      const keyList = ['address', 'city', 'stateValue', 'countryValue']
      return keyList
        .map(key => (this.billDetail || {})[key] || '')
        .filter(item => !!item)
        .join(',')
    },
    isPaySuccess() {
      return this.billDetail?.payStatus === PAY_STATUS.PAY_SUCCESS
    },
    showPayName() {
      if (this.billDetail?.payMethodType === codCode) {
        return true
      }
      return this.billDetail?.payStatus === PAY_STATUS.PAY_SUCCESS
    },
  },

  methods: {
    payOrder() {
      this.$emit('payOrder')
    },
  },
}
</script>
<style lang="less" scoped>
.bill-detail {
  padding: 0 24px 24px;
  &__title {
    padding: 24px 0;
    font-size: 22px;
    font-weight: 500;
    color: var(--color-title);
  }
  &__base {
    padding-bottom: 8px;
    border-bottom: 1px solid #f4f6f8;
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
    }
  }
  &__contact {
    padding-bottom: 8px;
    border-bottom: 1px solid #f4f6f8;
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
    }
  }
  &__info-title {
    color: var(--color-sub-title);
  }
  &__shipping {
    padding-bottom: 24px;
    &__detail {
      padding: 8px 16px;
      background: #f9fafb;
      &__item {
        line-height: 30px;
      }
    }
  }
  &__pay-btn {
    display: none;
    .op-button {
      width: 100%;
    }
  }
}
@media (max-width: 1024px) {
  .bill-detail {
    &__pay-btn {
      display: block;
    }
  }
}
</style>
