<template>
  <a-spin :spinning="spinning">
    <div class="order-detail">
      <div
        v-if="showGoBack"
        class="order-detail__header"
        @click="goOrderList"
      >
        <a-icon
          :style="{ fontSize: '18px', color: '#8991A4' }"
          type="left"
        />
        <div class="order-detail__header__text">{{ $t('account.orderDetail.back') }}</div>
      </div>
      <div class="order-detail__body">
        <div class="order-detail__body__left">
          <div class="order-detail__body__left__header">
            <span class="order-detail__body__left__header__title">{{ $t('account.orderDetail') }}</span>
            <a-statistic-countdown
              v-if="isShowCountdown"
              :prefix="$t('account.orderDetail.deadlinePrefix')"
              :suffix="$t('account.orderDetail.deadlineSuffix')"
              :value="deadline"
              @finish="countdownFinish"
            />
          </div>
          <product-list
            :order-no="orderBaseInfo.orderNo"
            :order-status="orderBaseInfo.orderStatus"
            :product-list="productList"
          ></product-list>
          <op-spin v-if="loading"></op-spin>
          <checkout-info
            v-if="!loading"
            :checkout-info="orderBaseInfo"
            @payOrder="payOrder"
          ></checkout-info>
          <delivery-info
            v-if="orderBaseInfo.orderStatus !== 600 && !orderBaseInfo.orderStatus !== 100"
            :from-email="fromEmail"
            :shipping-info="{
              shippersName: orderBaseInfo.shippersName,
              shippingOrderNo: orderBaseInfo.shippingOrderNo,
            }"
            :order-info="orderBaseInfo"
            @payOrder="payOrder"
            @isLoading="isLoading"
          ></delivery-info>
          <op-button
            class="order-detail__body__left__continue-shopping"
            @click="goProductList"
          >{{ $t('payment.success.continueShopping') }}</op-button>
        </div>
        <div class="order-detail__body__right">
          <billing-detail
            :bill-detail="billDetail"
            :shipping-type="orderBaseInfo.shippingType"
            :shipping-detail="shippingDetail"
            @payOrder="payOrder"
          ></billing-detail>
        </div>
      </div>
      <a-modal
        :closable="false"
        :mask-closable="false"
        :visible="visible"
      >
        <div>{{ $t('account.orderDetail.paidTip') }}</div>
        <template slot="footer">
          <op-button @click="closPaidTip">{{ $t('common.okay') }}</op-button>
        </template>
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
import { getOrderDetail, checkoutUnPayedOrder, cancelOrder, checkoutAbandonedOrder } from '@/api'
import CheckoutInfo from './components/checkout-info.vue'
import DeliveryInfo from './components/delivery-info.vue'
import ProductList from './components/product-list.vue'
import BillingDetail from './components/billing-detail.vue'
import { ORDER_STATUS, PAY_STATUS, SHIPPING_TYPE } from '@/constants'
import { mapState } from 'vuex'
export default {
  components: {
    CheckoutInfo,
    DeliveryInfo,
    ProductList,
    BillingDetail,
  },
  props: {},
  data() {
    return {
      spinning: false,
      SHIPPING_TYPE,
      orderDetail: {},
      orderBaseInfo: {},
      productList: [],
      billDetail: {},
      loading: true,
      visible: false,
      fromEmail: false,
      shippingDetail: {},
      codCode: 7,
    }
  },

  computed: {
    ...mapState(['isAlreadyLogin']),
    showGoBack() {
      if (this.$route.query.form === 'paySuccess') {
        return false
      }
      return true
    },
    deadline() {
      return this.billDetail.orderCountDownTime
    },
    isShowCountdown() {
      return (
        this.billDetail?.orderStatus === ORDER_STATUS.UNPAID &&
        this.billDetail?.payMethodType !== this.codCode
      )
    },
  },

  created() {
    this.getOrderDetail()
    // fromEmail 是否从邮箱过来 true是 此标识用来打开物流详情
    if (this.$route.query.fromEmail === 'true') {
      this.fromEmail = true
    }
  },

  methods: {
    goProductList() {
      this.$router.push('/products/all')
    },
    goOrderList() {
      if (!this.isAlreadyLogin) {
        this.$router.push({
          path: '/',
        })
        return
      }
      this.$router.push({
        path: '/account',
      })
    },
    async getOrderDetail() {
      try {
        this.loading = true
        const { data } = await getOrderDetail({ ...this.$route.query, ...this.$route.params })
        const response = data || {}
        this.orderBaseInfo = response.customerOrderBaseResultDTO || {}
        this.productList = response.orderItemDTOList || []
        this.billDetail = {
          ...response.customerOrderBaseResultDTO,
          ...response.orderDeliveryAddressDTO,
        }
        this.shippingDetail = response.shopTakeAddressDTO
      } finally {
        this.loading = false
      }
    },
    async cancelOrder() {
      await cancelOrder({
        orderNo: this.billDetail.orderNo,
        orderToken: this.billDetail.orderToken,
      })
      window.location.reload()
    },
    countdownFinish() {
      this.cancelOrder()
    },
    async payOrder() {
      const postInterface =
        this.$route.query.type === 'throwOrder' ? checkoutAbandonedOrder : checkoutUnPayedOrder
      const { data } = await postInterface({
        orderNo: this.billDetail.orderNo,
        orderToken: this.billDetail.orderToken,
      })
      const response = data || {}
      if (response.payStatus === PAY_STATUS.PAY_SUCCESS) {
        this.visible = true
        return
      }
      if (response.cashierUrl) {
        window.location.href = response.cashierUrl
      }
    },
    closPaidTip() {
      this.visible = false
      window.location.reload()
    },
    // 是否显示loading
    isLoading(data) {
      this.spinning = data
    },
  },
}
</script>
<style lang="less" scoped>
.order-detail {
  width: 1440px;
  max-width: calc(100% - 80px);
  padding-bottom: 24px;
  margin: 0 auto;
  &__header {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 16px 0;
    font-size: 16px;
    font-weight: 500;
    color: #8991a4;
    cursor: pointer;
    &__text {
      margin-right: 16px;
      margin-left: 8px;
      line-height: 24px;
    }
    /deep/ .ant-statistic-content {
      font-size: 16px;
    }
  }
  &__body {
    display: flex;
    &__left {
      flex: 1;
      margin-right: 24px;
      background: #fff;
      &__header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        color: var(--color-title);
        &__title {
          padding: 24px;
          font-size: 22px;
          font-weight: 500;
          line-height: 22px;
        }
        /deep/ .ant-statistic-content {
          padding: 0 24px;
          font-size: 16px;
        }
        /deep/ .ant-statistic-content-value {
          margin-right: 4px;
        }
        /deep/ .ant-statistic-content-suffix {
          margin-left: 0;
        }
      }
      &__continue-shopping {
        margin: 24px;
      }
    }
    &__right {
      width: 300px;
      background: #fff;
    }
  }
}

@media (max-width: 1024px) {
  .order-detail {
    max-width: calc(100% - 80px);
    margin: 0 auto;
    &__body {
      display: block;
      &__left {
        margin-right: 0;
      }
      &__right {
        width: 100%;
      }
    }
  }
}

@media (max-width: 576px) {
  .order-detail {
    max-width: calc(100% - 32px);
    margin: 0 auto;
  }
}
</style>
