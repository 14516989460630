<template>
  <div class="product-list">
    <a-row
      class="product-list-row product-list-row__header product-list-row--pc"
      type="flex"
      align="middle"
    >
      <a-col :style="{ 'min-width': '200px' }">{{ $t('account.orderDetail.product') }}</a-col>
      <a-col flex="1">{{ $t('account.orderDetail.price') }}</a-col>
      <a-col flex="1">{{ $t('account.orderDetail.number') }}</a-col>
      <a-col flex="1">{{ $t('account.orderDetail.total') }}</a-col>
      <a-col
        v-if="orderStatus === ORDER_STATUS.COMPLETED"
        flex="1"
      >{{ $t('account.orderDetail.Operate') }}</a-col>
    </a-row>
    <a-row
      v-for="item in productList"
      :key="item.orderNo"
      class="product-list-row product-list-row--pc"
      type="flex"
      align="middle"
    >
      <a-col :style="{ 'min-width': '200px' }">
        <div class="product-list-row__product-info">
          <img
            v-lazy="item.imgUrl"
            class="product-list-row__product-info__image"
          />
          <div class="product-list-row__product-info__summary">
            <div class="product-list-row__product-info__summary-name">{{ item.goodsName }}</div>
            <div class="product-list-row__product-info__summary-sku">{{ getSkuInfo(item) }}</div>
          </div>
        </div>
      </a-col>
      <a-col flex="1">{{ item.salePrice | formatMoney }}</a-col>
      <a-col flex="1">{{ item.skuNum }}</a-col>
      <a-col flex="1">
        <div>{{ item.saleTotalPrice | formatMoney }}</div>
      </a-col>
      <a-col
        v-if="orderStatus === ORDER_STATUS.COMPLETED"
        flex="1"
      >
        <op-link @click="goComment(item)">{{ $t('account.orderDetail.comment') }}</op-link>
      </a-col>
    </a-row>
    <a-row
      v-for="item in productList"
      :key="item.orderNo"
      class="product-list-row product-list-row--mobile"
      type="flex"
      align="middle"
    >
      <a-col flex="1">
        <div class="product-list-row__product-info">
          <img
            v-lazy="item.imgUrl"
            class="product-list-row__product-info__image"
          />
          <div class="product-list-row__product-info__summary">
            <div class="product-list-row__product-info__summary-name">{{ item.goodsName }}</div>
            <div class="product-list-row__product-info__summary-sku">{{ getSkuInfo(item) }}</div>
            <div class="product-list-row__product-info__checkout">
              <span>{{ item.salePrice | formatMoney }}</span>
              <span class="product-list-row__product-info__checkout-number">{{ `× ${item.skuNum}` }}</span>
            </div>
            <div>{{ item.total | formatMoney }}</div>
            <op-link
              v-if="orderStatus === ORDER_STATUS.COMPLETED"
              @click="goComment(item)"
            >{{ $t('account.orderDetail.comment') }}</op-link>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { ORDER_STATUS } from '@/constants'
export default {
  components: {},
  props: {
    productList: {
      type: Array,
      default: () => [],
    },
    orderStatus: {
      type: Number,
      default: ORDER_STATUS.COMPLETED,
    },
    orderNo: {
      type: [String, Number],
      default: -1,
    },
  },
  data() {
    return {
      ORDER_STATUS: ORDER_STATUS,
    }
  },

  computed: {},

  methods: {
    getSkuInfo(item) {
      return (item.orderItemSpecsDTOList || []).map(item => item.specsValueName).join(',')
    },
    goComment(item) {
      this.$router.push({
        path: '/comments/new',
        query: {
          orderNo: this.orderNo,
          orderItemNo: item.orderItemNo,
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
.product-list {
  padding: 24px;
  word-break: break-all;

  .product-list-row {
    /deep/ .ant-col {
      padding: 4px;
    }
    &__header {
      background: #f9fafb;
    }
    &__product-info {
      display: flex;
      align-items: center;
      &__image {
        display: block;
        width: 80px;
        height: 80px;
        object-fit: cover;
      }
      &__summary {
        flex: 1;
        padding-left: 16px;
        &-name {
        }
        &-sku {
          margin-top: 8px;
          color: #9daac2;
        }
      }
    }

    padding: 16px;
    border-bottom: 1px solid #f4f6f8;
  }
  .product-list-row--mobile {
    display: none;
  }
}
@media (max-width: 768px) {
  .product-list {
    .product-list-row--pc {
      display: none;
    }
    .product-list-row--mobile {
      display: block;
    }
    .product-list-row {
      &__product-info {
        &__checkout-number {
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
