<template>
  <a-modal
    class="order-delivery-detail"
    :title="$t('account.orderDetail.logisticsInfoTitle')"
    :visible="show"
    :footer="null"
    width="800px"
    @cancel="close"
  >
    <div class="info-box">
      <div class="content">
        <a-timeline
          v-for="item in detailArr"
          :key="item.trackTime"
        >
          <a-timeline-item>
            <div class="item">
              <div class="trackTime">{{ item.trackTime }}</div>
              <div class="trackRemark">{{ item.trackRemark }}</div>
            </div>
          </a-timeline-item>
        </a-timeline>
        <div
          v-if="detailArr.length == 0"
          class="noData"
        >{{ $t('account.orderDetail.noDeliveryDetail') }}</div>
        <!-- <div
          v-for="item in detailArr"
          :key="item.trackTime"
          class="item"
        >
          <div class="trackTime">{{ item.trackTime }}</div>
          <div class="trackRemark">{{ item.trackRemark }}</div>
        </div>-->
      </div>
    </div>
  </a-modal>
</template>
<script>
import { getDeliveryDetail } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    shippingInfo: {
      type: Object,
    },
  },
  data() {
    return {
      show: false,
      detailArr: [],
    }
  },
  created() {
    this.getDeliveryDetail()
  },
  methods: {
    async getDeliveryDetail() {
      this.$emit('isLoading', true)
      const params = {
        shippersName: this.shippingInfo.shippersName, // 承运商
        shippingOrderNo: this.shippingInfo.shippingOrderNo, // 运单号
      }
      // 这个接口很有可能在6秒内无法返回请求，此时需要手动关闭loading以及弹窗
      const loadingTimer = setTimeout(() => {
        this.$emit('isLoading', false)
        this.close()
      }, 6500)
      const res = await getDeliveryDetail(params)
      // console.log(res)
      this.$emit('isLoading', false)
      // 关闭定时器
      clearTimeout(loadingTimer)
      if (res.code === 200) {
        this.show = true
        this.detailArr = res.data
      } else {
        this.$message.error(res.message)
        this.show = false
        this.close()
      }
      // console.log(this.detailArr)
    },
    close() {
      // this.show = false
      this.$emit('close', { type: 'showDeliveryDetail' })
    },
  },
}
</script>
<style lang="less" scoped>
.order-delivery-detail {
  .info-box {
    // width: 600px
    max-height: 380px;
    // height: 500px;
    overflow-y: auto;
    .content {
      width: 700px;
      padding: 0 24px 24px;
      // height: 600px;
      // overflow: scroll;
      .noData {
        text-align: center;
      }
      .item {
        display: flex;
        justify-content: space-between;
        line-height: 30px;
        .trackTime {
          flex: 1;
          min-width: 150px;
        }
        .trackRemark {
          flex: 5;
          padding-left: 10px;
          text-align: left;
        }
      }
    }
  }
  /deep/ .ant-timeline-item-tail {
    display: block;
  }
  /deep/ .ant-timeline-item-head-blue {
    border-color: #00b876;
  }
}
</style>
