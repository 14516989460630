<template>
  <div class="checkout-info">
    <div class="checkout-info__item">
      <div class="checkout-info__item__title">{{ $t('account.orderDetail.subTotal') }}</div>
      <div class="checkout-info__item__amount">{{ checkoutInfo.originalAmount | formatMoney }}</div>
    </div>
    <div
      v-if="checkoutInfo.orderDiscountAmount > 0"
      class="checkout-info__item"
    >
      <div class="checkout-info__item__title">{{ $t('account.orderDetail.discount') }}</div>
      <div class="checkout-info__item__amount">{{ checkoutInfo.orderDiscountAmount | formatMoney }}</div>
    </div>
    <div
      v-if="checkoutInfo.taxAmount > 0"
      class="checkout-info__item"
    >
      <div class="checkout-info__item__title">{{ $t('account.orderDetail.tax') }}</div>
      <div class="checkout-info__item__amount">{{ checkoutInfo.taxAmount | formatMoney }}</div>
    </div>
    <div class="checkout-info__item">
      <div class="checkout-info__item__title">{{ $t('account.orderDetail.shippingRate') }}</div>
      <div class="checkout-info__item__amount">{{ checkoutInfo.deliveryAmount | formatMoney }}</div>
    </div>
    <div class="checkout-info__item">
      <div class="checkout-info__item__title">{{ $t('account.orderDetail.total') }}</div>
      <div
        class="checkout-info__item__amount amount-total--global"
      >{{ checkoutInfo.afterTaxActualAmount | formatMoney }}</div>
    </div>
    <div
      v-if="checkoutInfo.orderStatus === ORDER_STATUS.UNPAID && checkoutInfo.payMethodType !== 7"
      class="checkout-info__payment"
    >
      <op-button @click="payOrder">{{ $t('account.orderDetail.pay') }}</op-button>
    </div>
  </div>
</template>

<script>
import { ORDER_STATUS } from '@/constants'
export default {
  components: {},
  props: {
    checkoutInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return { ORDER_STATUS }
  },

  computed: {},

  methods: {
    payOrder() {
      this.$emit('payOrder')
    },
  },
}
</script>
<style lang="less" scoped>
.checkout-info {
  padding: 0 24px 24px;
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 30px;
    &__title {
      color: var(--color-sub-title);
    }
  }
  &__payment {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    .op-button {
      min-width: 100px;
    }
  }
}
@media (max-width: 1024px) {
  .checkout-info {
    &__payment {
      display: none;
    }
  }
}
</style>
